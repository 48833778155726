<template>
  <section
    id="footer"
    :class="{
      'bg-primary': 'primary',
      'bg-secondary': 'secondary',
      'bg-white': 'white',
    }"
  >
    <div class="row">
      <div class="address">
        <h1>SanThit Accelerator Myanmar</h1>
        <p>
          SanThit Accelerator is a gender inclusive Myanmar-focussed accelerator
          programme created in partnership with Emerging Markets Entrepreneurs -
          Myanmar, and Village Capital.
        </p>
      </div>
      <div class="contact">
        <h3>Contact Us</h3>
        <p>santhit@eme.asia</p>
      </div>
      <div class="parthner">
        <p>Website By</p>
        <p>Binary Lab</p>
      </div>
      <span class="copy">© 2022 Santhit Accelerator.All right reserved.</span>
    </div>
    <div class="footer-image-background"></div>
  </section>
</template>

<script>
export default {
  name: "Footer",
  props: ["color"],
};
</script>

<style lang="scss" scoped>
@import "@/scss/definations.scss";
@import "@/scss/footer.scss";
</style>

<template>
  <div class="home">
    <Hero />
    <CurveSection color="primary" top="true" fullWidth="true">
      <h2 class="title mb-3" data-content="Guiding Myanmar Startups">
        Guiding Myanmar Startups
      </h2>
      <VideoComponent />
    </CurveSection>
    <CurveSection id="new-approach" fullWidth="true" color="secondary">
      <div class="new-approach-content">
        <h2 class="title" data-content="A New Approach">A New Approach</h2>
        <p class="detail">
          At SanThit you'll spend more time proving your value proposition and
          less time practicing pitches as we coach you on the things that really
          matter for your business.
        </p>
      </div>

      <div class="new-approach-row">
        <div class="col">
          <img
            src="../assets/online_diagnostics_n_resources.png"
            alt="online_diagnostics_n_resources"
          />
          <h3 data-content="Online Diagnostics & Resources">
            Online Diagnostics & Resources
          </h3>
          <p>
            <a
              class="underline-link"
              href="https://forms.gle/Bht4Zn9GGkZmTbxFA"
            >
              Take our free business diagnostics test now to test your
              knowledge, and improve it by accessing our learning hub
            </a>
          </p>
        </div>
        <div class="col">
          <img src="../assets/global_curriculum.png" alt="global_curriculum" />
          <h3 data-content="Global Curriculum">Global Curriculum</h3>
          <p>
            20 companies will join our cohort and engage with Village Capital's
            global leading startup curriculum
          </p>
        </div>
        <div class="col">
          <img
            src="../assets/up_to_nine_months_hands_on_support.png"
            alt="up_to_nine_months_hands_on_support"
          />
          <h3 data-content="Up to 9 months hands on support">
            Up to 9 months hands on support
          </h3>
          <p>You'll get market-leading support from the EME Myanmar team</p>
        </div>
      </div>
      <img src="@/assets/underline_large.svg" alt="underline" />
    </CurveSection>
    <CurveSection color="white" fullWidth="true">
      <div id="what-we-value">
        <div class="row">
          <div class="col-img">
            <div class="img-container">
              <img src="../assets/we-value-image-1.png" alt="we value image" />
            </div>
          </div>
          <div class="col">
            <div class="message">
              <h3>We're here,</h3>
              <p>springboarding you into your seed-stage</p>
            </div>
          </div>
        </div>
      </div>
    </CurveSection>
    <CurveSection color="primary" fullWidth="true">
      <div id="we-belive">
        <h2 class="title" data-content="What We Value">What We Value</h2>
        <h3
          class="title small-title mb-3"
          data-content="We believe in progress over pitches"
        >
          We believe in progress over pitches
        </h3>
        <div class="progress-row">
          <div class="col">
            <img
              src="../assets/testing-assumptions.png"
              alt="testing-assumptions"
            />
            <h3 class="title" data-content="Testing Assumptions">
              Testing Assumptions
            </h3>
            <p>
              Through rapid testing and honest learning we can find pivots and
              improvements. We will help you test, learn and innovate.
            </p>
          </div>
          <div class="col">
            <img src="../assets/gender_equity.png" alt="gender_equity" />
            <h3 class="title" data-content="Gender Equity">Gender Equity</h3>
            <p>
              SanThit has been designed to be gender-smart and inclusive. We
              will help you ensure that your business is, too.
            </p>
          </div>
          <div class="col">
            <img src="../assets/value_creation.png" alt="value_creation" />
            <h3 class="title" data-content="Value Creation">Value Creation</h3>
            <p>
              We beleive in the power of entrepreneurship to change the world
              and we will help you unlock your company's potential
            </p>
          </div>
        </div>
      </div>
    </CurveSection>
    <CurveSection color="white" fullWidth="true" secondaryColor="true">
      <div id="what-founders-are-saying">
        <h2
          class="title-secondary custom-size"
          data-content='" What founders are saying about SanThit "'
        >
          " What founders are saying about SanThit "
        </h2>
        <Feedback />
      </div>
    </CurveSection>
    <CurveSection color="secondary">
      <RocketSection title="Get started today!" />
    </CurveSection>
    <Footer color="secondary" />
  </div>
</template>

<script>
import Hero from "@/components/Hero.vue";
import VideoComponent from "@/components/VideoComponent.vue";
import CurveSection from "@/components/CurveSection.vue";
import Feedback from "@/components/Feedback.vue";
import RocketSection from "@/components/RocketSection.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Home",
  created() {
    if (this.$store.state.isMenuOpen) this.$store.commit("toggleMenuOpen");
    document.title = this.$router.currentRoute.value.meta.title;
    window.scrollTo({ top: 0, behavior: "smooth" });
  },
  components: {
    Hero,
    CurveSection,
    Feedback,
    RocketSection,
    Footer,
    VideoComponent,
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/definations.scss";
@import "@/scss/home.scss";

.custom-size {
  @media (max-width: 900px) {
    font-size: 2.5rem;
  }
}

.underline-link {
  font-weight: bold;
  color: $white-color;
}
</style>

<template>
  <!-- <div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </div> -->
  <Navbar />
  <router-view />
</template>

<script>
import Navbar from "@/components/Navbar.vue";

export default {
  name: "Home",
  components: {
    Navbar,
  },
};
</script>

<style src="video.js/dist/video-js.css"></style>
<style lang="scss">
@import "@/scss/definations.scss";
/* @import url("@/assets/fonts/Lunch.TTF"); */
a {
  color: $secondary-color;
}

@font-face {
  font-family: "Lunch";
  src: local("Lunch"), url("./assets/fonts/Lunch.TTF") format("truetype");
}

@font-face {
  font-family: "Poppings Regular";
  src: local("Poppings Regular"),
    url("./assets/fonts/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Prater Block Fill Pro";
  src: local("Prater Block Fill Pro"),
    url("./assets/fonts/PraterBlockFillPro.otf") format("truetype");
}

@font-face {
  font-family: "Prater Block Background Pro";
  src: local("Prater Block Background Pro"),
    url("./assets/fonts/PraterBlockBackgroundPro.otf") format("truetype");
}

body {
  padding: 0;
  margin: 0;
  background-color: $secondary-color;
  font-family: "Poppings Regular", sans-serif;
  overflow-inline: hidden;
}

.center-layout {
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -10rem;
  padding-top: 8rem;

  &.with-curve {
    margin-bottom: 10rem;
  }
  h2 {
    font-size: 3.3rem;
    font-family: "Lunch", sans-serif;
    margin-bottom: 1.5rem;
  }
  h3 {
    font-size: 1.5rem;
    font-family: "Lunch", sans-serif;
  }
  p {
    width: 70%;
  }

  .curve {
    position: absolute;
    top: 100%;
    left: 0%;
    padding-inline: 0;
    margin-inline: 0;
    width: 100%;
    z-index: 100;
    // margin-top: 100rem;
  }
}

.half-width {
  width: 60%;
}

.title {
  font-size: 3rem;
  @include primary-font($dark-color, $primary-color);
}

.title-secondary {
  font-size: 3rem;
  @include primary-font($white-color, $secondary-color);
}

.bg-primary {
  background-color: $primary-color;
}

.bg-secondary {
  background-color: $secondary-color;
}

.text-white {
  color: $white-color;
}

.text-black {
  color: $black-color;
}

.mt-3 {
  margin-top: 3rem;
}

.mt-5 {
  margin-top: 5rem;
}

.mb-3 {
  margin-bottom: 3rem;
}

.mb-5 {
  margin-bottom: 5rem;
}
</style>

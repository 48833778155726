import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueGtag from "vue-gtag";

createApp(App)
  .use(store)
  .use(VueGtag, {
    config: { id: "G-FKMSFCWPF8" },
    router,
    enabled: true,
  })
  .use(router)
  .mount("#app");

<template>
  <nav>
    <div class="nav-content">
      <div class="nav-menu" @click="toggleMenuOpen">
        <img src="@/assets/icons/menu-button-wide.svg" alt="my-logo" />
        <span class="nav-text" data-content="Menu">Menu</span>
      </div>
    </div>
    <div class="nav-background"></div>
    <!-- <div class="container">
      <img class="nav-background" src="@/assets/nav_curve.svg" alt="my-logo" />
      <img
        class="nav-background-sm"
        src="@/assets/nav_small.svg"
        alt="my-logo"
      />
    </div> -->
  </nav>
  <transition name="menu">
    <div class="menu" v-show="isMenuOpen">
      <img
        src="../assets/menu_logo.png"
        alt="menu logo"
        @click="
          () => {
            goTo('Home');
          }
        "
      />
      <a
        class="menu-link hover-lg"
        :class="[{ active: currentPage === 'Home' }]"
      >
        <span
          class="title"
          data-content="SanThit Accelerator"
          @click="
            () => {
              goTo('Home');
            }
          "
        >
          SanThit Accelerator
        </span>
      </a>
      <a
        class="menu-link hover-lg"
        :class="[{ active: currentPage === 'About' }]"
      >
        <span
          class="title"
          data-content="About Us"
          @click="
            () => {
              goTo('About');
            }
          "
        >
          About Us
        </span>
      </a>
      <a
        class="menu-link hover-lg"
        :class="[{ active: currentPage === 'Past Cohorts' }]"
      >
        <span
          class="title"
          data-content="Past Cohorts"
          @click="
            () => {
              goTo('Past Cohorts');
            }
          "
        >
          Past Cohorts
        </span>
      </a>
      <a
        class="menu-link hover-lg"
        :class="[{ active: currentPage === 'Application & Program Process' }]"
      >
        <span
          class="title"
          data-content="Application & Program Process"
          @click="
            () => {
              goTo('Application & Program Process');
            }
          "
        >
          Application & Program Process
        </span>
      </a>
    </div>
  </transition>
  <div class="nav-bottom" v-show="isNotHome()" />
</template>

<script>
// import MenuIcon from "../assets/icons/menu-button-wide.svg";
export default {
  name: "Navbar",
  // components: {
  //   MenuIcon,
  // },
  methods: {
    toggleMenuOpen() {
      this.$store.dispatch("toggleMenuOpen");
    },
    goTo(pathname) {
      if (this.$router.currentRoute.value.name == pathname) {
        this.$store.commit("toggleMenuOpen");
      } else {
        this.$router.push({
          name: pathname,
        });
      }
    },
    isNotHome() {
      return this.$router.currentRoute.value.name != "Home";
    },
  },
  computed: {
    isMenuOpen() {
      return this.$store.state.isMenuOpen;
    },
    currentPage() {
      return this.$router.currentRoute.value.name;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/definations.scss";
@import "@/scss/navbar.scss";

.menu-enter-active {
  transition: all 1s ease-in-out;
}
.menu-leave-active {
  transition: all 1s ease-in-out;
}

.menu-enter-from {
  top: -100vh;
}
.menu-enter-to {
  top: 0vh;
}

.menu-leave-to {
  top: -100vh;
}

.container {
  position: relative;
  width: 100vw;
  .nav-background {
    /* content: url("../assets/yellow_curve.svg"); */
    position: absolute;
    top: 100%;
    margin-top: 2.5rem;
    left: -10%;
    width: 120%;
    height: auto;
    z-index: 1000;
  }
  .nav-background-sm {
    display: none;
  }

  @media (max-width: 900px) {
    .nav-background {
      display: none;
    }
    .nav-background-sm {
      position: absolute;
      display: block;
      top: 99.9%;
      margin-top: 2.5rem;
      left: -10%;
      width: 120%;
      height: auto;
      z-index: 1000;
    }
  }
}

.nav-bottom {
  padding-bottom: 3.7rem;

  @media (max-width: 900px) {
    padding-bottom: 3rem;
  }

}
</style>

<template>
  <transition name="feedback">
    <div
      class="feedback"
      :class="{
        active: feedback.show,
      }"
      v-show="feedback.show"
    >
      <p>
        {{ feedback.detail }}
      </p>
      <img :src="feedback.img" :alt="`image of ${feedback.name}`" />
      <h5>{{ feedback.name }}</h5>
      <h5>{{ feedback.position }} - {{ feedback.company }}</h5>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    feedback: {
      type: [Object, null],
      default: null,
    },
    current: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/definations.scss";

.feedback {
  height: 15rem;

  img {
    width: 5rem;
    border-radius: 50%;
    margin-bottom: 0;
  }
  p {
    width: 100%;
    font-size: 0.9rem;
    font-weight: lighter !important;
  }
  h5 {
    line-height: 0.7rem;
    font-weight: bolder;
    font-size: 1rem;
  }
}

.feedback-enter-active {
  transition-property: opacity;
  transition: opacity 0.5s ease;
  /* transition-delay: 0.7s !important; */
}
.feedback-leave-active {
  transition-property: opacity;
  transition: opacity 0.5s ease;
}

.feedback-enter-to {
  opacity: 1;
}

.feedback-enter-from {
  opacity: 0;
}

.feedback-leave-from {
  opacity: 1;
}

.feedback-leave-to {
  opacity: 0;
}
</style>

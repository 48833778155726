<template>
  <div class="container">
    <div class="slider">
      <template v-for="(feedback, i) in feedbacks" class="item" :key="i">
        <FeedbackItem :feedback="feedback" :current="current" />
      </template>
    </div>
    <img
      class="arrow arrow-left"
      src="../assets/icons/left-arrow-dark.svg"
      alt="left arrow"
      @click="leftClick()"
    />
    <img
      class="arrow arrow-right"
      src="../assets/icons/right-arrow-dark.svg"
      alt="right arrow"
      @click="rightClick()"
    />
  </div>
</template>

<script>
import feedbackData from "@/data/feedbacks.json";
import FeedbackItem from "@/components/FeedbackItem.vue";
export default {
  name: "Feedback",
  data() {
    return {
      feedbacks: feedbackData.data,
      current: 0,
    };
  },
  methods: {
    leftClick() {
      if (this.current > 0) {
        this.current = this.current - 1;
      } else {
        this.current = this.feedbacks.length - 1;
      }
      this.feedbacks.forEach((feedback) => {
        feedback.show = false;
      });
      setTimeout(() => {
        this.feedbacks[this.current].show = true;
      }, 1000);
    },
    rightClick() {
      if (this.current < this.feedbacks.length - 1) {
        this.current = this.current + 1;
      } else {
        this.current = 0;
      }
      this.feedbacks.forEach((feedback) => {
        feedback.show = false;
      });
      setTimeout(() => {
        this.feedbacks[this.current].show = true;
      }, 500);
    },
  },
  components: {
    FeedbackItem,
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/definations.scss";

.container {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100vw;

  .slider {
    margin-top: 1rem;
    height: 15rem;
    width: 35%;
  }

  .arrow {
    position: absolute;
    top: 24%;
    width: 4rem;
    cursor: pointer;
  }

  .arrow-left {
    left: 20%;
  }

  .arrow-right {
    right: 20%;
  }

  // 3000 // 2600 // 2500
  @media (max-width: 3000px) {
    .slider {
      margin-top: 1rem;
      width: 60%;
    }

    .arrow {
      position: absolute;
      top: 30%;
      width: 6rem;
    }

    .arrow-left {
      left: 5%;
    }

    .arrow-right {
      right: 5%;
    }
  }

  // 2400 // 2300 // 2000
  @media (max-width: 2400px) {
    .slider {
      margin-top: 2rem;
      width: 60%;
    }

    .arrow {
      position: absolute;
      top: 30%;
      width: 6rem;
    }

    .arrow-left {
      left: 5%;
    }

    .arrow-right {
      right: 5%;
    }
  }

  // 1920 common
  @media (max-width: 1920px) {
    .slider {
      margin-top: 2rem;
      width: 50%;
    }

    .arrow {
      position: absolute;
      top: 30%;
      width: 6rem;
    }

    .arrow-left {
      left: 8%;
    }

    .arrow-right {
      right: 8%;
    }
  }
  // 1440 common // 1366 common // 1024 common
  @media (max-width: 1440px) {
    .slider {
      margin-top: 2rem;
      width: 40%;
    }

    .arrow {
      position: absolute;
      top: 30%;
      width: 5rem;
    }

    .arrow-left {
      left: 15%;
    }

    .arrow-right {
      right: 15%;
    }
  }

  // 960 common
  @media (max-width: 960px) {
    .slider {
      margin-top: 2rem;
      width: 40%;
    }

    .arrow {
      position: absolute;
      top: 20%;
      width: 5rem;
    }

    .arrow-left {
      left: 15%;
    }

    .arrow-right {
      right: 15%;
    }
  }

  // 800  // 700 // 600
  @media (max-width: 800px) {
    .slider {
      margin-top: 2rem;
      width: 40%;
    }

    .arrow {
      position: absolute;
      top: 15%;
      width: 5rem;
    }

    .arrow-left {
      left: 15%;
    }

    .arrow-right {
      right: 15%;
    }
  }

  // 480 common // 390 // 360 common // 320 common
  @media (max-width: 800px) {
    .slider {
      margin-top: 2rem;
      width: 70%;
    }

    .arrow {
      position: absolute;
      top: 15%;
      width: 3.7rem;
    }

    .arrow-left {
      left: 0%;
    }

    .arrow-right {
      right: 0%;
    }
  }
}
</style>
